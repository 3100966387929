export const toParams = (
  urlQuery: string | null,
): Record<string, string | Array<string> | undefined> => {
  if (!urlQuery) return {}

  const searchParams = new URLSearchParams(urlQuery)
  const params: Record<string, string | Array<string> | undefined> = {}

  searchParams.forEach((value, key) => {
    const potentialArrayKeyMarker = key.substring(key.length - 2, key.length)

    if (potentialArrayKeyMarker === '[]') {
      const arrayKey = key.substring(0, key.length - 2)
      params[arrayKey] = params[arrayKey] || []

      // @ts-ignore: it's safe to push.
      params[arrayKey].push(value)

      return
    }

    params[key] = value
  })

  return params
}

export const toUrlQuery = (params: object): string => {
  return Object.keys(params)
    .map(key => {
      // @ts-ignore: it's safe to access.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const value = params[key]

      if (Array.isArray(value)) {
        return value.map(item => `${key}[]=${encodeURIComponent(item)}`).join('&')
      }

      return `${key}=${encodeURIComponent(value)}`
    })
    .join('&')
}
