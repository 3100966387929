import { z } from 'zod'

const SUPPORTED_COUNTRIES = ['FR', 'IT', 'ES', 'BE', 'NL', 'GB', 'PT'] as const

export type SupportedCountry = (typeof SUPPORTED_COUNTRIES)[number]

export const countrySchema = z.enum(SUPPORTED_COUNTRIES)

export function isSupportedCountry(
  value: string | Array<string> | undefined,
): value is SupportedCountry {
  if (!value) return false
  if (Array.isArray(value)) return false

  return SUPPORTED_COUNTRIES.includes(value.toUpperCase() as SupportedCountry)
}
