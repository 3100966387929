import { useEffect } from 'react'

import { useTracking } from 'lib/context/tracking'

type Props = {
  event: string
}

export const usePageVisitTracking = ({ event }: Props) => {
  const { track } = useTracking()

  useEffect(() => {
    track({ event })
  }, [event, track])
}
