import { Money } from 'lib/types/money'
import { SupportedCurrencies } from 'lib/types/currency'

export const STANDARD_COVERAGE_PRICE: Money = {
  amount: '0',
  currency: SupportedCurrencies.EUR,
}

type CoverageByCurrency = Record<SupportedCurrencies, Money>

export const STANDARD_COVERAGE_AMOUNT: CoverageByCurrency = {
  EUR: {
    amount: '25',
    currency: SupportedCurrencies.EUR,
  },
  GBP: {
    amount: '20',
    currency: SupportedCurrencies.GBP,
  },
}

export const EXTRA_COVERAGE_AMOUNT: CoverageByCurrency = {
  EUR: {
    amount: '500',
    currency: SupportedCurrencies.EUR,
  },
  GBP: {
    amount: '400',
    currency: SupportedCurrencies.GBP,
  },
}
