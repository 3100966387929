import { ReactNode } from 'react'
import { Text } from '@vinted/web-ui'
import classNames from 'classnames'

import styles from './RedesignedInput.module.css'

// TODO: Similar input is used in cs form for `svc-vintedgo-ui` so it probably makes sense to create new input style in vinted go web-ui.
type Props = {
  testId?: string
  note?: ReactNode
  title?: ReactNode
  suffix?: ReactNode
  prefix?: ReactNode
  validation?: ReactNode
  name: string
  children: ReactNode
}

const RedesignedInputWrapper = ({
  name,
  note,
  title,
  suffix,
  prefix,
  testId,
  children,
  validation,
}: Props) => {
  const validationOrNote = validation || note

  const className = classNames(styles.redesignedInput, {
    [styles.redesignedInputPrefix]: !!prefix,
    [styles.redesignedInputError]: !!validation,
  })

  return (
    <div data-testid={testId ? `${testId}--wrapper` : undefined}>
      {title ? (
        <div className={styles.title}>
          {typeof title === 'string' ? <Text text={title} type={Text.Type.Subtitle} /> : title}
        </div>
      ) : null}

      <div id={`RedesignedInput_${name}`} className={className}>
        {prefix ? <div className={styles.prefix}>{prefix}</div> : null}
        {children}
        {suffix ? <div className={styles.suffix}>{suffix}</div> : null}
      </div>

      {validationOrNote ? (
        <div
          data-testid={testId ? `${testId}--wrapper--validation` : undefined}
          className={styles.validationOrNote}
        >
          {validationOrNote}
        </div>
      ) : null}
    </div>
  )
}

export default RedesignedInputWrapper
