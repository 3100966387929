import { timeZones } from 'lib/constants/timeZones'
import { DEFAULT_COUNTRY } from 'lib/data/countries'
import { SupportedCountry, isSupportedCountry } from 'lib/types/country'

const getSystemTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone
// Typescript and lint does not tell that array element potentially is undefined.
// eslint-disable-next-line
const getCountriesForTimeZone = (timeZone: string) => timeZones[timeZone]?.c || []

export const getDefaultCountry = (): SupportedCountry => {
  const countriesForTimeZone = getCountriesForTimeZone(getSystemTimeZone())
  const supportedCountry = countriesForTimeZone.find(isSupportedCountry)

  return supportedCountry || DEFAULT_COUNTRY
}
