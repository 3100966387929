import { useMemo } from 'react'
import { useTranslation } from 'next-i18next'

import { SupportedCountry } from 'lib/types/country'
import { RoutesModel } from 'lib/types/models/route'

type CountrySelectOption = {
  value: SupportedCountry
  label: string
}

export const useTranslatedShippingRoutes = (
  routes: RoutesModel | null,
  fromCountry?: SupportedCountry,
) => {
  const { t } = useTranslation('common')

  const fromCountries = useMemo<Array<CountrySelectOption>>(() => {
    if (!routes) return []

    return Object.keys(routes).map(c => ({ value: c as SupportedCountry, label: t(c) }))
  }, [t, routes])

  const toCountries = useMemo(() => {
    if (!routes || !fromCountry) return []

    return routes[fromCountry].map(c => ({ value: c.toCountry, label: t(c.toCountry) }))
  }, [t, routes, fromCountry])

  return [fromCountries, toCountries]
}
