import { Money } from 'lib/types/money'
import { IntlProps } from 'lib/types/intl'
import { DEFAULT_CURRENCY, FALLBACK_LOCALE_ISO } from 'lib/constants/locale'

export type CurrencyFormatParams = {
  formatAsNegative?: boolean
  keepFractionDigits?: boolean
}

const DEFAULT_CURRENCY_FORMAT_PARAMS: Required<CurrencyFormatParams> = {
  formatAsNegative: false,
  keepFractionDigits: true,
}

const NO_FRACTION_DIGITS_CURRENCY_OPTIONS: Partial<Intl.NumberFormatOptions> = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

const formatCurrency = (
  rawValue: string | number | null | undefined,
  props: IntlProps,
  params?: CurrencyFormatParams,
) => {
  const { locale, currency } = props
  const { formatAsNegative, keepFractionDigits } = {
    ...DEFAULT_CURRENCY_FORMAT_PARAMS,
    ...params,
  }

  const value = rawValue || 0
  const numberValue = formatAsNegative ? Number(value) * -1 : Number(value)

  const fractionDigitsSetup = !keepFractionDigits && NO_FRACTION_DIGITS_CURRENCY_OPTIONS

  return new Intl.NumberFormat(locale || FALLBACK_LOCALE_ISO, {
    currency: currency || DEFAULT_CURRENCY,
    style: 'currency',
    useGrouping: false,
    ...fractionDigitsSetup,
  }).format(numberValue)
}

const formatMoney = (money: Money, locale?: string, params?: CurrencyFormatParams) =>
  formatCurrency(money.amount, { locale, currency: money.currency }, params)

const getCurrencySymbol = (props: Pick<IntlProps, 'currency' | 'locale'>) => {
  const { locale, currency } = props

  return new Intl.NumberFormat(locale || FALLBACK_LOCALE_ISO, {
    currency: currency || DEFAULT_CURRENCY,
    style: 'currency',
  })
    .formatToParts(1)
    .find(({ type }) => type === 'currency')?.value
}

export { formatMoney, formatCurrency, getCurrencySymbol }
