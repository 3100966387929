import { useMemo, useState, useEffect, FormEvent, ReactNode, MouseEvent, ChangeEvent } from 'react'
import { Card, Cell, Icon, Text, Button } from '@vinted/web-ui'
import { Dropdown12 } from '@vinted/monochrome-icons'

import OutsideClick from 'components/OutsideClick'
import SeparatedList from 'components/SeparatedList'
import ScrollableArea from 'components/ScrollableArea'
import { RedesignedInputText } from 'components/RedesignedInputText'

import styles from './DropdownSelect.module.css'

const DROPDOWN_HEIGHT = 235

export type DropdownSelectOption<T extends string = string> = {
  value: T
  label: string
}

type Props<T extends string = string> = {
  value?: string
  isDisabled?: boolean
  placeholder?: string
  validation?: ReactNode
  title?: string | JSX.Element
  isQueryInputDisabled?: boolean
  onToggle?: (isDropdownOpen: boolean) => void

  name: string
  options: Array<DropdownSelectOption<T>>
  onOptionSelect: (option: T) => void
}

const DropdownSelect = <T extends string = string>({
  name,
  title,
  value,
  options,
  onToggle,
  validation,
  onOptionSelect,
  placeholder = '',
  isDisabled = false,
  isQueryInputDisabled = false,
}: Props<T>) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [renderedOptions, setRenderedOptions] = useState(options)

  const selectedOption = useMemo(
    () => options.find(option => option.value === value),
    [options, value],
  )

  useEffect(() => {
    setRenderedOptions(options)
  }, [options])

  useEffect(() => onToggle?.(isDropdownOpen), [onToggle, isDropdownOpen])

  const closeDropdown = () => {
    setIsDropdownOpen(false)
    setRenderedOptions(options)
  }

  const toggleDropdown = (event: MouseEvent) => {
    event.preventDefault()
    setIsDropdownOpen(prevState => !prevState)
    setRenderedOptions(options)
  }

  const handleInputClick = (event: MouseEvent) => {
    event.preventDefault()

    if (isDropdownOpen) return

    setIsDropdownOpen(true)
    setRenderedOptions(options)
  }

  const handleOptionSelect = (option: DropdownSelectOption<T>) => () => {
    onOptionSelect(option.value)
    closeDropdown()
  }

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>,
  ) => {
    const query = event.currentTarget.value.toLowerCase()

    const optionsMatchingInputQuery = options.filter(country =>
      country.label.toLowerCase().match(query),
    )

    setRenderedOptions(optionsMatchingInputQuery)
  }

  return (
    <OutsideClick isDisabled={isDisabled} onOutsideClick={closeDropdown}>
      <Cell theme="transparent" styling={Cell.Styling.Tight} onClick={handleInputClick}>
        <RedesignedInputText
          name={name}
          title={title}
          disabled={isDisabled}
          placeholder={placeholder}
          value={selectedOption?.label}
          readOnly={isQueryInputDisabled}
          validation={!isDropdownOpen && validation}
          onChange={handleInputChange}
          testId={`dropdown-select-${name}`}
          suffix={
            <Button
              disabled={isDisabled}
              size={Button.Size.Medium}
              styling={Button.Styling.Flat}
              iconPosition={Button.IconPosition.Right}
              icon={<Icon name={Dropdown12} color={Icon.Color.GreyscaleLevel3} />}
              testId={`dropdown-select-${name}--dropdown-arrow`}
              onClick={toggleDropdown}
            />
          }
        />
      </Cell>
      {isDropdownOpen ? (
        <div
          className="u-position-relative"
          data-testid={`dropdown-select-${name}--dropdown-values`}
        >
          <div className={styles.dropdown}>
            <Card styling={Card.Styling.Elevated}>
              <div className="u-overflow-hidden">
                <ScrollableArea maxHeight={DROPDOWN_HEIGHT}>
                  <SeparatedList>
                    {renderedOptions.map((option, index) => (
                      <Cell
                        key={option.value}
                        type={Cell.Type.Navigating}
                        body={<Text text={option.label} type={Text.Type.Title} />}
                        testId={`dropdown-select-${name}--dropdown-list-item-${index}`}
                        onClick={handleOptionSelect(option)}
                      />
                    ))}
                  </SeparatedList>
                </ScrollableArea>
              </div>
            </Card>
          </div>
        </div>
      ) : null}
    </OutsideClick>
  )
}

export default DropdownSelect
