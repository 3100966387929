import { useState, useEffect } from 'react'

import {
  findRouteIn,
  getFirstRoute,
  getFirstRouteFrom,
  getRouteFromUrlParams,
  getRouteBasedOnBrowserTimezone,
} from 'lib/utils/route'
import { toParams } from 'lib/utils/url'
import { getUrlQuery } from 'lib/utils/window'
import { RouteModel, RoutesModel } from 'lib/types/models/route'

export const usePrefilledRoute = (routes: RoutesModel | null) => {
  const [prefilledRoute, setPrefilledRoute] = useState<RouteModel | null>(null)

  useEffect(() => {
    if (!routes) return

    const preferredRouteQuery =
      getRouteFromUrlParams(toParams(getUrlQuery())) || getRouteBasedOnBrowserTimezone()
    const preferredRoute = findRouteIn(routes, preferredRouteQuery)

    const updatedPrefilledRoute =
      preferredRoute ||
      getFirstRouteFrom(preferredRouteQuery.fromCountry, routes) ||
      getFirstRoute(routes)

    setPrefilledRoute(updatedPrefilledRoute)
  }, [routes])

  return prefilledRoute
}
