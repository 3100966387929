import { ChangeEvent, ReactNode, useEffect, useState } from 'react'

import { Cell, Icon, Button, InputText as InputTextrWebUI } from '@vinted/web-ui'
import { X16 } from '@vinted/monochrome-icons'

import RedesignedInputWrapper from './RedesignedInputWrapper'

type Props = {
  note?: ReactNode
  title?: ReactNode
  prefix?: ReactNode
  onValueClear?: () => void
  placeholder: string
} & Omit<ComponentProps<typeof InputTextrWebUI>, 'title' | 'placeholder'>

const RedesignedInputText = ({
  name,
  note,
  title,
  prefix,
  suffix,
  testId,
  validation,
  uncontrolled,
  onValueClear,
  ...props
}: Props) => {
  const [inputValue, setInputValue] = useState(props.value)
  const [isClearButtonVisible, setIsClearButtonVisible] = useState(
    !!props.value && !!onValueClear && !suffix,
  )

  useEffect(() => {
    if (uncontrolled) return

    setInputValue(props.value)
    setIsClearButtonVisible(!!props.value && !!onValueClear && !suffix)
  }, [suffix, props.value, uncontrolled, onValueClear])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setIsClearButtonVisible(!!value && !!onValueClear && !suffix)

    props.onChange?.(event)
  }

  const handleClearButtonClick = () => {
    setIsClearButtonVisible(false)
    setInputValue(undefined)
    onValueClear?.()
  }

  return (
    <RedesignedInputWrapper
      name={name}
      note={note}
      title={title}
      testId={testId}
      prefix={prefix}
      validation={validation}
      suffix={
        isClearButtonVisible ? (
          <Cell
            styling={Cell.Styling.Tight}
            body={
              <Button
                iconName={X16}
                iconColor={Icon.Color.GreyscaleLevel3}
                size={Button.Size.Medium}
                styling={Button.Styling.Flat}
                onClick={handleClearButtonClick}
                testId={testId ? `${testId}--input-clear-button` : undefined}
              />
            }
          />
        ) : (
          suffix
        )
      }
    >
      <InputTextrWebUI
        {...props}
        name={name}
        testId={testId}
        title={undefined}
        validation={undefined}
        uncontrolled={uncontrolled}
        value={uncontrolled ? props.value : inputValue}
        onChange={handleOnChange}
      />
    </RedesignedInputWrapper>
  )
}

export default RedesignedInputText
