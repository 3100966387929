import { IncomingHttpHeaders } from 'http'

import { RouteModel, RoutesModel } from 'lib/types/models/route'
import { RouteQuery, PartialRouteQuery } from 'lib/types/routeQuery'
import { SupportedCountry, isSupportedCountry } from 'lib/types/country'

import { getDefaultCountry } from './country'

export const isSupportedRoute = (
  routeQuery: PartialRouteQuery | null,
  routes: RoutesModel | null,
) => {
  if (!routes || !routeQuery) return false

  const { fromCountry, toCountry } = routeQuery

  if (!fromCountry || !toCountry) return false

  // Current TS version does not check for potentially undefined object property.
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return routes[fromCountry]?.some(country => country.toCountry === toCountry)
}

export const getRouteBasedOnBrowserTimezone = (): RouteQuery => {
  const defaultCountry = getDefaultCountry()

  return {
    fromCountry: defaultCountry,
    toCountry: defaultCountry,
  }
}

export const getRouteFromUrlParams = (
  urlParams: Record<string, string | Array<string> | undefined>,
): RouteQuery | null => {
  const { from_country, to_country } = urlParams

  if (!from_country || Array.isArray(from_country)) return null
  if (!to_country || Array.isArray(to_country)) return null

  const fromCountry = from_country.toUpperCase()
  const toCountry = to_country.toUpperCase()

  if (!isSupportedCountry(fromCountry) || !isSupportedCountry(toCountry)) return null

  return {
    fromCountry,
    toCountry,
  }
}

export const getRouteFromRequestHeaders = (headers: IncomingHttpHeaders): RouteQuery | null => {
  const cloudflareIpCountryHeader = headers['cf-ipcountry']

  if (isSupportedCountry(cloudflareIpCountryHeader)) {
    const country = cloudflareIpCountryHeader.toUpperCase() as SupportedCountry

    return {
      fromCountry: country,
      toCountry: country,
    }
  }

  return null
}

export const getFirstRouteFrom = (
  fromCountry: SupportedCountry,
  routes: RoutesModel,
): RouteModel | null => {
  // Current TS version does not check for potentially undefined object property.
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return routes[fromCountry]?.[0] || null
}

export const getFirstRoute = (routes: RoutesModel): RouteModel | null => {
  const firstDefinedRoutesFromSomeCountry = Object.values(routes).find(routesFromCountry => {
    // Current TS version does not check for potentially undefined object property.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return routesFromCountry?.length ? routesFromCountry : undefined
  })

  return firstDefinedRoutesFromSomeCountry?.[0] || null
}

export const findRouteIn = (
  routes: RoutesModel | null,
  routeQuery: PartialRouteQuery | null,
): RouteModel | null => {
  if (!routes || !routeQuery) return null
  if (!routeQuery.fromCountry || !routeQuery.toCountry) return null

  return (
    // Current TS version does not check for potentially undefined object property.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    routes[routeQuery.fromCountry]?.find(route => {
      return route.toCountry === routeQuery.toCountry
    }) || null
  )
}
