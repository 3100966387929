import { ReactNode } from 'react'
import { InputTextArea as InputTextAreaWebUI } from '@vinted/web-ui'

import RedesignedInputWrapper from './RedesignedInputWrapper'

type Props = {
  note?: ReactNode
  title?: ReactNode
  placeholder: string
} & Omit<ComponentProps<typeof InputTextAreaWebUI>, 'title' | 'placeholder'>

const RedesignedInputTextArea = ({
  ref,
  name,
  note,
  title,
  testId,
  suffix,
  validation,
  ...props
}: Props) => {
  return (
    <RedesignedInputWrapper
      name={name}
      note={note}
      title={title}
      testId={testId}
      validation={validation}
    >
      <InputTextAreaWebUI
        {...props}
        name={name}
        testId={testId}
        title={undefined}
        validation={undefined}
      />
    </RedesignedInputWrapper>
  )
}

export default RedesignedInputTextArea
