export const getUrlHash = () => (typeof window === 'undefined' ? null : window.location.hash)

export const getUrlQuery = () => (typeof window === 'undefined' ? null : window.location.search)

export const getRelativeUrl = () =>
  typeof window === 'undefined' ? null : window.location.pathname

export const navigateToPage = (path: string) => {
  if (typeof window === 'undefined') return

  window.location.assign(path)
}
