export const timeZones: Readonly<Record<string, { c: Array<string> }>> = {
  'Europe/Amsterdam': { c: ['NL'] },
  'Europe/Andorra': { c: ['AD'] },
  'Europe/Astrakhan': { c: ['RU'] },
  'Europe/Athens': { c: ['GR'] },
  'Europe/Belfast': { c: ['GB'] },
  'Europe/Belgrade': { c: ['RS', 'BA', 'HR', 'ME', 'MK', 'SI'] },
  'Europe/Berlin': { c: ['DE', 'DK', 'NO', 'SE', 'SJ'] },
  'Europe/Bratislava': { c: ['SK'] },
  'Europe/Brussels': { c: ['BE', 'LU', 'NL'] },
  'Europe/Bucharest': { c: ['RO'] },
  'Europe/Budapest': { c: ['HU'] },
  'Europe/Busingen': { c: ['DE'] },
  'Europe/Chisinau': { c: ['MD'] },
  'Europe/Copenhagen': { c: ['DK'] },
  'Europe/Dublin': { c: ['IE'] },
  'Europe/Gibraltar': { c: ['GI'] },
  'Europe/Guernsey': { c: ['GG'] },
  'Europe/Helsinki': { c: ['FI', 'AX'] },
  'Europe/Isle_of_Man': { c: ['IM'] },
  'Europe/Istanbul': { c: ['TR'] },
  'Europe/Jersey': { c: ['JE'] },
  'Europe/Kiev': { c: ['UA'] },
  'Europe/Kyiv': { c: ['UA'] },
  'Europe/Lisbon': { c: ['PT'] },
  'Europe/Ljubljana': { c: ['SI'] },
  'Europe/London': { c: ['GB', 'GG', 'IM', 'JE'] },
  'Europe/Luxembourg': { c: ['LU'] },
  'Europe/Madrid': { c: ['ES'] },
  'Europe/Malta': { c: ['MT'] },
  'Europe/Mariehamn': { c: ['AX'] },
  'Europe/Minsk': { c: ['BY'] },
  'Europe/Monaco': { c: ['MC'] },
  'Europe/Oslo': { c: ['NO'] },
  'Europe/Paris': { c: ['FR', 'MC'] },
  'Europe/Podgorica': { c: ['ME'] },
  'Europe/Prague': { c: ['CZ', 'SK'] },
  'Europe/Riga': { c: ['LV'] },
  'Europe/Rome': { c: ['IT', 'SM', 'VA'] },
  'Europe/San_Marino': { c: ['SM'] },
  'Europe/Sarajevo': { c: ['BA'] },
  'Europe/Simferopol': { c: ['UA'] },
  'Europe/Skopje': { c: ['MK'] },
  'Europe/Sofia': { c: ['BG'] },
  'Europe/Stockholm': { c: ['SE'] },
  'Europe/Tallinn': { c: ['EE'] },
  'Europe/Tirane': { c: ['AL'] },
  'Europe/Tiraspol': { c: ['MD'] },
  'Europe/Uzhgorod': { c: ['UA'] },
  'Europe/Vaduz': { c: ['LI'] },
  'Europe/Vatican': { c: ['VA'] },
  'Europe/Vienna': { c: ['AT'] },
  'Europe/Vilnius': { c: ['LT'] },
  'Europe/Warsaw': { c: ['PL'] },
  'Europe/Zagreb': { c: ['HR'] },
  'Europe/Zaporozhye': { c: ['UA'] },
  'Europe/Zurich': { c: ['CH', 'DE', 'LI'] },
}
