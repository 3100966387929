import { ReactNode, useRef } from 'react'
import { useEffectOnce, useToggle, useUpdateEffect } from 'usehooks-ts'

type Props = {
  children: ReactNode
  className?: string
  isDisabled?: boolean
  onOutsideClick: (event: MouseEvent) => void
}

const OutsideClick = ({ children, className, isDisabled = false, onOutsideClick }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [listenersAttached, toggleListenersAttached] = useToggle()

  const onMouseDown = (event: MouseEvent) => {
    const { current: node } = wrapperRef

    if (!node || isDisabled) return
    if (event.target instanceof Node && node.contains(event.target)) return

    onOutsideClick(event)
  }

  const attachListener = () => document.addEventListener('mousedown', onMouseDown)

  const removeListener = () => document.removeEventListener('mousedown', onMouseDown)

  useEffectOnce(() => {
    if (isDisabled) return undefined

    attachListener()
    toggleListenersAttached()

    return removeListener
  })

  useUpdateEffect(() => {
    if (isDisabled || listenersAttached) return

    attachListener()
    toggleListenersAttached()
  }, [isDisabled])

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  )
}

export default OutsideClick
