import { STATIC_ASSET_BASE_PATH } from 'lib/constants/image'

export const getStaticAssetUrl = (assetSubpath: string) => {
  return [STATIC_ASSET_BASE_PATH, assetSubpath]
    .map(path => {
      const trimmedPath = path.endsWith('/') ? path.substring(0, path.length - 1) : path

      return trimmedPath.startsWith('/') ? trimmedPath.substring(1) : trimmedPath
    })
    .join('/')
}
